<template>
    <el-radio-group v-model="current">
        <el-radio label="" v-if="all">全部</el-radio>
        <el-radio :label="d.id" v-for="(d,i) in dataList" :key="i">{{d.name}}</el-radio>
    </el-radio-group>
</template>

<script>
    export default {
        name: 'radio-area',
        props: {
            value: {
                required: true
            },
            all: {
                type: Boolean,
                default: false
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                dataList: [],
                current: this.value,
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.current = val
                }

            },
            current: {
                immediate: true,
                handler(newVal) {
                    this.$emit('change', newVal)
                }
            }
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/surgery/area/`)
                this.dataList = resp.data.data
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
