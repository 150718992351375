<template>
    <div>
        <!--                <el-radio-group v-model="form.date" @change="getOperationTimes" v-if="operationDates.length > 0">-->
        <!--                    <el-radio :class="d.msg|dateColor" :label="d.date" v-for="(d,i) in operationDates" :key="i"-->
        <!--                              :disabled="d.disabled" v-show="visibleDisable(d.disabled)" style="padding:5px 0;width:130px">-->
        <!--                        <span>{{d.date}}({{d.count}}/{{d.total}})</span>-->
        <!--                    </el-radio>-->
        <!--                </el-radio-group>-->
        <!--                <div v-else>请先选择手术医生和院区</div>-->
        <el-radio-group :value="value" @input="$emit('input', $event)" v-if="options.length>0">
            <el-radio v-for="item in options" :key="item.date" :label="item.date" :disabled="item.disabled" style="padding:5px 0;width:130px">
                {{item.date|getDate}}({{ item.week }})<small>({{item.count}}/{{item.total}})</small>
            </el-radio>
        </el-radio-group>
        <div v-else>请选择医生</div>
    </div>
</template>
<script>
    export default {
        name: "radio-date",
        props: {
            value: {
                type: [String, Number,]
            },
            doctor: {
                required: true
            },
            area: {
                required: true
            }
        },
        watch: {
            area(){
                this.getData()
            },
            doctor(){
                this.getData()
            }
        },
        data() {
            return {
                options: []
            }
        },
        filters: {
            dateColor(m) {
                if (m === '假')
                    return 'colorGreen'
                if (m === '停')
                    return 'colorRed'
                if (m === '临')
                    return 'colorBlue'
            },
            getDate(d) {
                return d.substring(5,10)
            }
        },
        methods: {
            async getData() {
                if (this.area && this.doctor) {
                    let area_ids = []
                    this.area.forEach(e=>{
                        area_ids.push(e.id)
                    })
                    const resp = await this.$http.get(`/surgery/operation/dates/?doctor=${this.doctor}&area=${area_ids}`)
                    console.log(resp)
                    this.options = resp.data.data || []
                }
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>

<style lang="scss" scoped>
</style>
