import Vue from 'vue'
import ElementUI from 'element-ui'
import App from './App.vue'
import http from './plugins/http.js'
import router from './router/index'
import store from './store/index'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import Print from 'vue-print-nb'
import adminTable from './components/tables/adminTable'
import components from './components/base'

Vue.use(components)
Vue.component('admin-table', adminTable)

Vue.use(Print);  //注册
// Vue.use(QuillEditor)
// Vue.use(BaiduMap, {
//   ak: 'e3aNTetY3SjrFtEQNImLuOFMnG1lfiBf'
// })
Vue.use(ElementUI);

Vue.prototype.version = window.version
Vue.prototype.$http = http
Vue.config.productionTip = false

/**
 * 日期格式化
 */
Date.prototype.format = function (format) {
  let o = {
    "M+": this.getMonth() + 1, //month
    "d+": this.getDate(), //day
    "h+": this.getHours(), //hour
    "m+": this.getMinutes(), //minute
    "s+": this.getSeconds(), //second
    "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
    "S": this.getMilliseconds() //millisecond
  }
  if (/(y+)/.test(format)) {
    format = format.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  for (let k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return format;
}
// 数组删除
Array.prototype.remove = function(val) {
  let index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
};

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
