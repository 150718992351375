<template>
    <div>
        <el-button @click="uploadTime" size="small">
            选择日期
        <el-date-picker class="time-date-picker" propper-class="time-panel-picker" ref="tiemPick"
                        size="small"
                        type="dates" value-format="yyyy-MM-dd" v-model="currentDates"
                        placeholder="选择一个或多个日期" @change="handleSelect"/>
        </el-button>
        <div class="tags">
            <el-tag v-for="(t,i) in currentDates" :key="i">{{t}}</el-tag>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'select-dates',
        props: {
            times: {
                type: Array,
                require: true,
                default: () => {
                    return []
                }
            },
        },
        model: {
            prop: 'times',
            event: 'change'
        },
        data() {
            return {
                currentDates: this.times
            }
        },
        watch: {
            times: {
                immediate: true,
                // console.log(val, 'time')
                handler(val) {
                    if (val.length > 0) {
                        this.currentDates = val
                    }
                }

            },
            currentDates: {
                immediate: true,
                // console.log(val, 'time')
                handler(newVal) {
                    this.$emit('change', newVal)
                }

            }

        },
        methods: {
            uploadTime() {
                // console.log(this.$refs)
                // if (this.$refs.tiemPick.$el)
                this.$refs.tiemPick.focus();
                // document.querySelector('.time-date-picker').querySelector('input').focus()
            },
            handleSelect() {
                this.$emit('ch', this.currentDates)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .box {
        position: relative;
    }
    .time-date-picker {
        position: absolute;
        top: 4px;
        left: 0;
        z-index: -1;
        width: 0;
    }

    .tags {
        margin-top: 10px
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
</style>
