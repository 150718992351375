<template>
    <el-select v-model="current" placeholder="选择医生" @input="commit" size="mini" :clearable="true">
        <el-option v-for="item in options" :key="item.id" :label="item.realname" :value="item.realname"></el-option>
    </el-select>
</template>
<script>
    export default {
        name: "select-doctor",
        props: {
            value: {
                type: [String, Number,]
            },
            size: {
                default: 'small'
            }
        },
        data() {
            return {
                current:this.value,
                options:[]
            }
        },
        methods: {
            commit(){
                this.$emit('input',this.current)
                this.$emit('change',this.current)
            },
            async getData() {
                const hl = await this.$http.get('/auth/doctor/')
                this.options = hl.data.data || []
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>
<style lang="scss" scoped>
</style>
