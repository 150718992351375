<template>
    <el-checkbox-group v-model="current" @input="input" @change="handleChange">
        <el-checkbox :label="w" v-for="(w, i) in options" :key="i">{{w.name}}</el-checkbox>
    </el-checkbox-group>
</template>
<script>
    export default {
        name: "checkbox-area",
        props: {
            value: {
                // type: [String, Number, Array],
                default: () => [],
                required: true
            },
            doctor: {
                required: true
            }
        },
        watch: {
            doctor() {
                this.getData()
            }
        },
        data() {
            return {
                options: [],
                current: []
            }
        },
        methods: {
            handleChange() {
                // 仅李老师可以多选
                if (this.current.length > 1 && this.doctor!='李航') {
                    this.current.splice(0, 1)
                }
                this.$emit('input', this.current)
            },
            input($event) {
                console.log($event)
                // this.$emit('input', this.current)
            },
            async getData() {
                // console.log(this.value)
                // if (this.doctor) {
                const resp = await this.$http.get(`/surgery/area/?doctor=${this.doctor}`)
                this.options = resp.data.data || []
                let cks = []
                this.options.forEach(e => {
                    this.value.forEach(f => {
                        if (e.id == f.id) {
                            cks.push(e)
                        }
                    })
                })
                this.current = cks
                // }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>

</style>
