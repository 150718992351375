<template>
    <div>
<!--        <el-radio-group v-model="form.time" v-if="operationTimes.length > 0">-->
<!--            <el-radio :label="d.time" v-for="(d,i) in operationTimes" :key="i" :disabled="d.disabled"-->
<!--                      style="padding:5px 0">-->
<!--                {{d.time}}({{d.used}}/{{d.count}})-->
<!--            </el-radio>-->
<!--        </el-radio-group>-->
        <el-radio-group :value="value" @input="$emit('input', $event)" v-if="options.length>0">
            <el-radio v-for="(item, index) in options" :key="index" :label="item.time" :disabled="item.disabled"
                      style="padding:5px 0;width:130px">
                {{item.time}}({{item.used}}/{{item.count}})
            </el-radio>
        </el-radio-group>
        <div v-else>请先选择预约日期</div>
    </div>
</template>
<script>
    export default {
        name: "radio-time",
        props: {
            value: {
                type: [String, Number]
            },
            doctor: {
                required: true
            },
            date: {
                required: true
            },
            area: {
                required: true
            }
        },
        watch: {
            area() {
                this.getData()
            },
            date() {
                this.getData()
            },
            doctor() {
                this.getData()
            }
        },
        data() {
            return {
                options: []
            }
        },
        methods: {
            async getData() {
                if (this.area && this.doctor && this.date) {
                    let area_ids = []
                    this.area.forEach(e=>{
                        area_ids.push(e.id)
                    })
                    const resp = await this.$http.get(`/surgery/operation/times/?doctor=${this.doctor}&area=${area_ids}&date=${this.date}`)
                    this.options = resp.data.data || []
                }
            }
        },
        mounted() {
            this.getData()
        }
    };
</script>

<style lang="scss" scoped>
</style>
