import Vue from 'vue'
import Vuex from 'vuex'
import http from '../plugins/http'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        app: {
            version: '',
        },
        username: '',
        routerHistory: [],
        messages: [],
        weekList: [
            {name: '周日', value: 0},
            {name: '周一', value: 1},
            {name: '周二', value: 2},
            {name: '周三', value: 3},
            {name: '周四', value: 4},
            {name: '周五', value: 5},
            {name: '周六', value: 6},
        ],
        doctorList: [],
        areaList: [],
        worksheetList: [],
        vacationList: {data: [], total: 0},
    },
    mutations: {
        set_version(state, data) {
            state.app.version = data.version
        },
        set_username(state, username) {
            localStorage.setItem('username', username)
            state.username = username
        },
        set_token(state, token) {
            localStorage.setItem('token', token)
            state.token = token
        },
        setRouterHistory(state, data) {
            let exist = false
            state.routerHistory.forEach(item=>{
                if(item.path===data.path){
                    exist = true
                }
            })
            if (!exist) {
                state.routerHistory.unshift(data)
            }
            let max = 5
            if (state.routerHistory.length > max) {
                state.routerHistory = state.routerHistory.slice(0, max)
            }
        },
        delRouterHistory(state, index) {
            state.routerHistory.splice(index, 1)
        },
        set_doctorList(state, data) {
            state.doctorList = data
        },
        set_areaList(state, data) {
            state.areaList = data
        },
        set_worksheetList(state, data) {
            state.worksheetList = data
        },
        set_vacationList(state, data) {
            state.vacationList = data
        },
    },
    getters: {
        username: state => {
            return state.username || localStorage.getItem('username')
        },
        token: state => {
            return state.token || localStorage.getItem('token')
        }
    },
    actions: {
        getVersion: ({commit}) => {
            http.get('/version').then(resp => {
                commit('set_version', resp.data)
            })
        },
        getDoctorList: ({commit}) => {
            http.get(`/auth/doctor/`)
                .then(resp => {
                    if (resp.data.code == 200) commit('set_doctorList', resp.data.data)
                }).catch(e => {
                console.log('getDoctorList', e)
            })
        },
        // eslint-disable-next-line no-unused-vars
        getAreaList: async ({commit}) => {
            const resp = http.get(`/surgery/area/`)
            // if (resp.data.code == 200) commit('set_areaList', resp.data.data)
            return resp
        },
        // eslint-disable-next-line no-unused-vars
        appoint_form: ({commit}, data) => {
            let method = data.id ? 'put' : 'post'
            let url = data.ID ? `/surgery/appoint/${this.form.id}` : `/surgery/appoint/`
            return new Promise((resolve, reject) => {
                http[method](url, data).then(res => {
                    resolve(res)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        worksheet_form: ({commit}, data) => {
            let method = data.id ? 'put' : 'post'
            let url = data.id ? `/surgery/worksheet/${data.id}` : `/surgery/worksheet/`
            return new Promise((resolve, reject) => {
                http[method](url, data).then(res => {
                    resolve(res)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        getVacationList: ({commit}) => {
            // http.get(`/surgery/vacation/`)
            //     .then(resp => {
            //         if (resp.data.code == 200) commit('set_vacationList', resp.data)
            //     }).catch(e => {
            //     console.log('getAreaList', e)
            // })
        },
        // eslint-disable-next-line no-unused-vars
        vacation_form: ({commit}, data) => {
            let method = data.id ? 'put' : 'post'
            let url = data.id ? `/surgery/vacation/${data.id}` : `/surgery/vacation/`
            return new Promise((resolve, reject) => {
                http[method](url, data).then(res => {
                    resolve(res.data)
                }).catch(e => {
                    reject(e)
                })
            })
        },
        login: ({commit}, data) => {
            return new Promise((resolve, reject) => {
                http.post(`/auth/login`, data)
                    .then(function (res) {
                        if (res.data.code == 200) {
                            commit('set_username', data.username)
                            commit('set_token', res.data.data.token)
                        }
                        resolve(res.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        }
    }
})

store.dispatch('getVersion').then()
export default store
