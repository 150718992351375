<template>
    <el-radio-group :value="value" @input="$emit('input',$event)">
        <el-radio v-for="(item, index) in options" :key="index" :label="item">{{item}}</el-radio>
    </el-radio-group>
</template>

<script>
    export default {
        name: "radio-type",
        props: {
            value: {
                type: [String, Number,]
            }
        },
        data() {
            return {
                options: ['常规手术', '特需手术']
            }
        },
        methods: {
            // async getData() {
            //     const hl = await this.$http.get('/auth/doctor/')
            //     this.options = hl.data.data || []
            // }
        },
        mounted() {
            // this.getData()
        }
    }
</script>

<style lang="scss" scoped>
</style>
