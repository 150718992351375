import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', redirect: '/login'},
        {path: '/test', component: () => import(`@/pages/main/test`)},
        {path: '/login', component: () => import(`@/pages/main/login2`)},
        {path: '/logout', component: () => import(`@/pages/main/logout`)},
        {
            path: '/main/password', component: () => import(`@/components/layouts/layout_blank`),
            children: [
                {path: '', component: () => import(`@/pages/main/password`)},
            ]
        },
        {
            path: '/main', component: () => import(`@/components/layouts/layout_admin`),
            children: [
                {path: '', redirect: '/main/dashboard'},
                {path: 'dashboard', component: () => import(`@/pages/dashboard`)},
                {path: 'message', component: () => import(`@/pages/main/message`)},

                {path: 'appoint', component: () => import(`@/pages/appoint`)},
                {path: 'appoint/notice', component: () => import(`@/pages/appoint/notice`)},
                {path: 'appoint/info/', component: () => import(`@/pages/appoint/info`)},
                {path: 'appoint/calendar', component: () => import(`@/pages/appoint/calendar`)},
                {path: 'appoint/outliers', component: () => import(`@/pages/appoint/outliers`)},
                {path: 'appoint/form/', component: () => import(`@/pages/appoint/form`)},
                {path: 'appoint/form/done/', component: () => import(`@/pages/appoint/form_done`)},
                // {path: 'appoint/vip', component: () => import(`@/pages/appoint/vip`)},

                {path: 'worksheet', component: () => import(`@/pages/worksheet`)},
                {path: 'worksheet/cycle', component: () => import(`@/pages/worksheet/cycle`)},
                {path: 'worksheet/cycle/form', component: () => import(`@/pages/worksheet/cycle_form`)},
                {path: 'worksheet/special', component: () => import(`@/pages/worksheet/special`)},
                {path: 'worksheet/special/form', component: () => import(`@/pages/worksheet/special_form`)},
                {path: 'worksheet/overtime', component: () => import(`@/pages/worksheet/overtime`)},
                {path: 'worksheet/overtime/form', component: () => import(`@/pages/worksheet/overtime_form`)},
                {path: 'worksheet/vacation', component: () => import(`@/pages/worksheet/vacation`)},
                {path: 'worksheet/vacation/form', component: () => import(`@/pages/worksheet/vacation_form`)},

                {path: 'advice', component: () => import(`@/pages/advice/index`)},
                {path: 'advice/advice', component: () => import(`@/pages/advice/advice`)},

                {path: 'advance', component: () => import(`@/pages/advance/index`)},
                {path: 'training', component: () => import(`@/pages/training/index`)},
                {path: 'agreement', component: () => import(`@/pages/agreement/index`)},
                {path: 'operation', component: () => import(`@/pages/operation/index`)},
                {path: 'assess', component: () => import(`@/pages/assess/index`)},

                {path: 'theater', component: () => import(`@/pages/theater/index`)},
                {path: 'theater/prepare', component: () => import(`@/pages/theater/prepare`)},

                {path: 'files/docs', component: () => import(`@/pages/files/docs`)},
                {path: 'files/medias', component: () => import(`@/pages/files/medias`)},
                {path: 'files/wiki', component: () => import(`@/pages/files/wiki`)},

                {path: 'data/appoint/import', component: () => import(`@/pages/data/appoint_import`)},

                {path: 'settings/origin', component: () => import(`@/pages/settings/origin`)},
                {path: 'settings/area', component: () => import(`@/pages/settings/area`)},
                {path: 'settings/theater', component: () => import(`@/pages/settings/theater`)},
                {path: 'settings/joborder', component: () => import(`@/pages/settings/joborder`)},
                {path: 'settings/users', component: () => import(`@/pages/settings/users`)},
                {path: 'settings/menu', component: () => import(`@/pages/settings/menu`)},
                {path: 'statistics', component: () => import(`@/pages/statistics`)},
                {path: 'oplog', component: () => import(`@/pages/oplog`)},
                {path: '*', component: () => import(`@/pages/main/notFound`)}
            ]
        },
        {path: '*', component: () => import(`@/pages/main/notFound`)}
    ]
})

export default router
